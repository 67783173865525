import {
	Network, NetworkId,
	setupWalletSelector,
	Wallet as NearWallet,
	WalletSelector,
} from '@near-wallet-selector/core'
import { setupModal } from '@near-wallet-selector/modal-ui'

import '@near-wallet-selector/modal-ui/styles.css'
import { setupNearWallet } from '@near-wallet-selector/near-wallet'
import nearWalletIcon from 'assets/near-wallet-icon.svg'
import { providers, transactions } from 'near-api-js'
import app_config from '../config/app_config'

interface FunctionCallError {
	error: {
		index: number
		kind: object
		message: string
		transaction_outcome: object
		type: 'FunctionCallError'
	}
}

interface ICallMethod {
	contractId: string
	method: string
	gas: string
	deposit: string
	args: object[]
}

export interface INearTransfer {
	receiverId: string
	deposit: string
}

export interface SignAndSendTransactionResponse {
	error?: string
	response?: Array<providers.FinalExecutionOutcome> | FunctionCallError
}

// Wallet that simplifies using the wallet selector
export class Wallet {
	walletSelector?: WalletSelector

	network: NetworkId | Network

	wallet?: NearWallet

	createAccessKeyFor: string | null = null

	accountId: string | null = null

	constructor({ createAccessKeyFor }: { createAccessKeyFor: string }) {
		// Login to a wallet passing a contractId will create a local
		// key, so the user skips signing non-payable transactions.
		// Omitting the accountId will result in the user being
		// asked to sign all transactions.
		this.createAccessKeyFor = createAccessKeyFor
		this.network = app_config.env.REACT_APP_NEAR_NETWORK as NetworkId
	}

	// To be called when the website loads
	async startUp() {
		this.walletSelector = await setupWalletSelector({
			network: this.network,
			modules: [setupNearWallet({ iconUrl: nearWalletIcon })],
		})

		const isSignedIn = this.walletSelector.isSignedIn()

		if (isSignedIn) {
			this.wallet = await this.walletSelector.wallet()
			this.accountId =
				this.walletSelector.store.getState().accounts[0].accountId
		}

		return isSignedIn
	}

	// Sign-in method
	signIn() {
		if (this.walletSelector && this.createAccessKeyFor) {
			const description = 'Please select a wallet to sign in.'
			const modal = setupModal(this.walletSelector, {
				contractId: this.createAccessKeyFor,
				description,
			})

			modal.show()
		}
	}

	async callMethod({
		contractId,
		method,
		args,
		gas,
		deposit,
	}: ICallMethod): Promise<
		void | providers.FinalExecutionOutcome | SignAndSendTransactionResponse
	> {
		const isSender = Wallet.isSenderActive(this.accountId as string)
		const actions = args.map(
			(arg) =>
				transactions.functionCall(
					method,
					arg,
					gas as any,
					deposit as any,
					undefined,
					isSender
				).functionCall as any
		)
		const receiverId = contractId

		return isSender
			? await window.near?.signAndSendTransaction({
				receiverId,
				actions,
			})
			: await this.wallet?.signAndSendTransaction({
				receiverId,
				actions: actions.map((params) => ({
					type: 'FunctionCall',
					params,
				})),
			})
	}

	async transfer({
		receiverId,
		deposit,
	}: INearTransfer): Promise<any> {
		const isSender = Wallet.isSenderActive(this.accountId as string)

		return isSender
			? await window.near?.sendMoney({ receiverId, amount: deposit })
			: await this.wallet?.signAndSendTransaction({
				receiverId,
				actions: [{
					type: 'Transfer',
					params: {
						deposit
					},
				}],
			})
	}

	async bulkTransfer(params: INearTransfer[]): Promise<any> {
		const isSender = Wallet.isSenderActive(this.accountId as string)
		const tParams = {
			transactions: params.map(({ receiverId, deposit }) => ({
				receiverId,
				actions: [{
					type: 'Transfer',
					params: {
						deposit
					},
				}],
			}))
		}

		return isSender
			? await window.near?.requestSignTransactions(tParams as any)
			: await this.wallet?.signAndSendTransactions(tParams as any)
	}

	async getTransactionResult(txhash: Uint8Array | string) {
		// @ts-ignore
		const { network } = this.walletSelector.options
		const provider = new providers.JsonRpcProvider({ url: network.nodeUrl })

		return provider.txStatus(txhash, 'unnused')
	}

	static isSenderActive(account: string | null | undefined): boolean {
		return (
			typeof window.near === 'object' &&
			typeof account === 'string' &&
			window.near.isSignedIn() &&
			window.near.getAccountId() === account
		)
	}

	static async signOut(
		selector: WalletSelector,
		walletAccount: string | null | undefined
	) {
		try {
			const wallet = await selector.wallet()

			await wallet.signOut()
		} catch (e) {
			// silent
		}

		if (walletAccount && Wallet.isSenderActive(walletAccount)) {
			await window.near?.signOut()
		}
	}
}
