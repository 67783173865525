import { ProtocolEnumType } from 'redux/invoice/types'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'

export const GUSD_EXPONENT = 2
export const USD_EXPONENT = 6
export const ETH_EXPONENT = 18
export const WRAP_EXPONENT = 24

function cleanupAmount(amount: string): string {
	return amount.replace(/,/g, '').trim()
}

function trimLeadingZeroes(value: string): string {
	value = value.replace(/^0+/, '')
	if (value === '') {
		return '0'
	}
	return value
}

export function getExponentByToken(token: WalletAccountBalanceToken) {
	switch (token) {
		case WalletAccountBalanceToken.NEAR:
		case WalletAccountBalanceToken.USD_NEAR:
		case WalletAccountBalanceToken.WRAP_NEAR:
		case WalletAccountBalanceToken.WRAP_NEAR_TESTNET:
			return WRAP_EXPONENT
		case WalletAccountBalanceToken.ETH:
		case WalletAccountBalanceToken.WRAP_ETH:
		case WalletAccountBalanceToken.WRAP_ETH_SEPOLIA:
		case WalletAccountBalanceToken.ETH_BUSD:
		case WalletAccountBalanceToken.ETH_BUSD_SEPOLIA:
		case WalletAccountBalanceToken.BSC_USDT:
		case WalletAccountBalanceToken.BSC_USDT_TESTNET:
		case WalletAccountBalanceToken.BSC_USDC:
		case WalletAccountBalanceToken.BSC_USDC_TESTNET:
		case WalletAccountBalanceToken.BSC_BUSD:
		case WalletAccountBalanceToken.BSC_BUSD_TESTNET:
		case WalletAccountBalanceToken.POLYGON_BUSD:
		case WalletAccountBalanceToken.POLYGON_BUSD_TESTNET:
			return ETH_EXPONENT
		case WalletAccountBalanceToken.ETH_GUSD:
		case WalletAccountBalanceToken.ETH_GUSD_SEPOLIA:
			return GUSD_EXPONENT
		default:
			return USD_EXPONENT
	}
}

export function getExponentByProtocol(protocol: ProtocolEnumType) {
	switch (protocol) {
		case ProtocolEnumType.NEAR:
			return WRAP_EXPONENT
		case ProtocolEnumType.ETH:
		case ProtocolEnumType.BSC:
		case ProtocolEnumType.POLYGON:
			return ETH_EXPONENT
		default:
			return USD_EXPONENT
	}
}

export function parseAmount(
	amount: string,
	token: WalletAccountBalanceToken
): string {
	const exponent = getExponentByToken(token)

	amount = cleanupAmount(amount)

	const split = amount.split('.')
	const wholePart = split[0]
	const fracPart = split[1] || ''

	if (split.length > 2 || fracPart.length > exponent) {
		throw new Error(`Cannot parse '${amount}'`)
	}

	return trimLeadingZeroes(wholePart + fracPart.padEnd(exponent, '0'))
}

export function formatCryptoAmount(
	amount: number,
	token: WalletAccountBalanceToken,
	fractionDigits = 2
): string {
	const exponent = getExponentByToken(token)

	return (amount * (10 ** -exponent)).toFixed(fractionDigits)
}

export function formatCryptoAmountByProtocol(
	amount: number,
	protocol: ProtocolEnumType,
	fractionDigits = 2
): string {
	const exponent = getExponentByProtocol(protocol)

	return (amount * (10 ** -exponent)).toFixed(fractionDigits)
}
