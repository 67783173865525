import { MutableRefObject } from 'react'
import { createInvoiceRequest, defaultRowData } from 'redux/invoice/reducer'
import {
	IInvoice,
	IInvoiceUser,
	IStatusType,
	ProtocolEnumType,
} from 'redux/invoice/types'
import { ITeam, IUser, RoleEnumType, TeamRoleEnumType } from 'redux/user/types'
import {
	TokenSymbols,
	WalletAccountBalanceToken,
} from 'redux/walletAccount/types'
import { CoinPriceSymbols } from 'redux/coinPrices/types'
import { isFiatCurrencyToken } from 'utils/wallets'
import { hasTeamUserRoles } from 'utils/userUtils'

export const getCustomerRecipientTitle = (
	user?: IUser | IInvoiceUser,
	team?: ITeam | null,
	role: RoleEnumType = RoleEnumType.CONTRACTOR
): string => {
	if (!user) {
		return ''
	}

	if (!user.name || (role === RoleEnumType.COMPANY && !team?.name)) {
		return user.email!
	}

	if (role === RoleEnumType.COMPANY && team && team?.name) {
		return team.name
	}

	return role === RoleEnumType.CONTRACTOR
		? `${user.name} ${user.surname}`
		: user.name!
}

export const getCustomerRecipientAvatar = (
	user?: IUser | IInvoiceUser,
	team?: ITeam | null,
	role?: RoleEnumType
): null | string | undefined => {
	if (!user) {
		return null
	}

	if (role === RoleEnumType.COMPANY && team && team?.name) {
		return team.logo
	}

	return user.avatar
}

export const getCustomerRecipientTitleAvatar = (
	user?: IUser | IInvoiceUser,
	team?: ITeam | null,
	role?: RoleEnumType
): {
	title: string,
	avatar: string | null | undefined
} => ({
	title: getCustomerRecipientTitle(user, team, role),
	avatar: getCustomerRecipientAvatar(user, team, role)
})

export const getCustomerRecipientSubtitle = (
	user?: IUser | IInvoiceUser,
	team?: ITeam | null,
	role?: RoleEnumType
) => {
	if (role === RoleEnumType.CONTRACTOR && user?.email) {
		return user.email
	}

	if (role === RoleEnumType.COMPANY && team?.address) {
		return team.address
	}

	return ''
}

export const getProtocolByToken = (token: WalletAccountBalanceToken) => {
	switch (token) {
		case WalletAccountBalanceToken.NEAR:
		case WalletAccountBalanceToken.WRAP_NEAR:
		case WalletAccountBalanceToken.WRAP_NEAR_TESTNET:
		case WalletAccountBalanceToken.NEAR_USDTT:
		case WalletAccountBalanceToken.NEAR_USDTT_TESTNET:
		case WalletAccountBalanceToken.NEAR_USDT:
		case WalletAccountBalanceToken.NEAR_USDT_TESTNET:
		case WalletAccountBalanceToken.NEAR_USDC:
		case WalletAccountBalanceToken.NEAR_USDC_TESTNET:
		case WalletAccountBalanceToken.USD_NEAR:
			return ProtocolEnumType.NEAR
		case WalletAccountBalanceToken.WRAP_TRON:
		case WalletAccountBalanceToken.WRAP_TRON_NILE:
		case WalletAccountBalanceToken.TRON_USDT:
		case WalletAccountBalanceToken.TRON_USDT_NILE:
			return ProtocolEnumType.TRON
		case WalletAccountBalanceToken.BSC_USDT:
		case WalletAccountBalanceToken.BSC_USDT_TESTNET:
		case WalletAccountBalanceToken.BSC_USDC:
		case WalletAccountBalanceToken.BSC_USDC_TESTNET:
		case WalletAccountBalanceToken.BSC_BUSD:
		case WalletAccountBalanceToken.BSC_BUSD_TESTNET:
			return ProtocolEnumType.BSC
		case WalletAccountBalanceToken.POLYGON_USDT:
		case WalletAccountBalanceToken.POLYGON_USDT_TESTNET:
		case WalletAccountBalanceToken.POLYGON_USDC:
		case WalletAccountBalanceToken.POLYGON_USDC_TESTNET:
		case WalletAccountBalanceToken.POLYGON_BUSD:
		case WalletAccountBalanceToken.POLYGON_BUSD_TESTNET:
			return ProtocolEnumType.POLYGON
		default:
			return ProtocolEnumType.ETH
	}
}

export const getDraftInvoice = (
	token: WalletAccountBalanceToken,
	senderId: string,
	receiverId: string,
	team: ITeam | null
): IInvoice => ({
	id: '',
	receiver: { id: receiverId },
	sender: {
		id: senderId,
	},
	tokens: {
		[token]: {
			units: [
				{
					...defaultRowData,
				},
			],
		},
	},
	team,
	token,
	comment: '',
	contract: null,
	status: 'draft',
	total_amount: 0,
	token_price: 0,
	transactions: [],
	tags: [],
})

export const createInvoice = (
	dispatch: any,
	token: WalletAccountBalanceToken,
	senderId: string,
	userId: string,
	team: ITeam | null,
	isNavigateToInvoice: MutableRefObject<{ value: boolean }>,
	usePreviousInvoiceToken = false
) => {
	dispatch(
		createInvoiceRequest({
			invoices: [getDraftInvoice(token, senderId, userId, team)],
			usePreviousInvoiceToken
		})
	)

	isNavigateToInvoice.current = { value: true }
}

export const tokenSymbols = {
	[WalletAccountBalanceToken.NOT_DEFINED]: TokenSymbols.NOT_DEFINED,
	[WalletAccountBalanceToken.NEAR_USDTT]: TokenSymbols.NEAR_USDTT,
	[WalletAccountBalanceToken.NEAR_USDTT_TESTNET]: TokenSymbols.NEAR_USDTT,
	[WalletAccountBalanceToken.NEAR_USDT]: TokenSymbols.NEAR_USDT,
	[WalletAccountBalanceToken.NEAR_USDT_TESTNET]: TokenSymbols.NEAR_USDT,
	[WalletAccountBalanceToken.NEAR_USDC]: TokenSymbols.NEAR_USDC,
	[WalletAccountBalanceToken.NEAR_USDC_TESTNET]: TokenSymbols.NEAR_USDC,
	[WalletAccountBalanceToken.WRAP_NEAR]: TokenSymbols.WRAPPED_NEAR,
	[WalletAccountBalanceToken.WRAP_NEAR_TESTNET]: TokenSymbols.WRAPPED_NEAR,
	[WalletAccountBalanceToken.NEAR]: TokenSymbols.NEAR,
	[WalletAccountBalanceToken.USD_NEAR]: TokenSymbols.USD,
	[WalletAccountBalanceToken.ETH]: TokenSymbols.ETH,
	[WalletAccountBalanceToken.WRAP_ETH]: TokenSymbols.WRAPPED_ETH,
	[WalletAccountBalanceToken.WRAP_ETH_SEPOLIA]: TokenSymbols.WRAPPED_ETH,
	[WalletAccountBalanceToken.ETH_USDC]: TokenSymbols.USDC,
	[WalletAccountBalanceToken.ETH_USDC_SEPOLIA]: TokenSymbols.USDC,
	[WalletAccountBalanceToken.ETH_USDT]: TokenSymbols.USDT,
	[WalletAccountBalanceToken.ETH_USDT_SEPOLIA]: TokenSymbols.USDT,
	[WalletAccountBalanceToken.ETH_BUSD]: TokenSymbols.BUSD,
	[WalletAccountBalanceToken.ETH_BUSD_SEPOLIA]: TokenSymbols.BUSD,
	[WalletAccountBalanceToken.ETH_GUSD]: TokenSymbols.GUSD,
	[WalletAccountBalanceToken.ETH_GUSD_SEPOLIA]: TokenSymbols.GUSD,
	[WalletAccountBalanceToken.TRON]: TokenSymbols.TRON,
	[WalletAccountBalanceToken.WRAP_TRON]: TokenSymbols.WRAPPED_TRON,
	[WalletAccountBalanceToken.WRAP_TRON_NILE]: TokenSymbols.WRAPPED_TRON,
	[WalletAccountBalanceToken.TRON_USDT]: TokenSymbols.USDT,
	[WalletAccountBalanceToken.TRON_USDT_NILE]: TokenSymbols.USDT,
	[WalletAccountBalanceToken.BSC_USDT]: TokenSymbols.USDT,
	[WalletAccountBalanceToken.BSC_USDT_TESTNET]: TokenSymbols.USDT,
	[WalletAccountBalanceToken.BSC_USDC]: TokenSymbols.USDC,
	[WalletAccountBalanceToken.BSC_USDC_TESTNET]: TokenSymbols.USDC,
	[WalletAccountBalanceToken.BSC_BUSD]: TokenSymbols.BUSD,
	[WalletAccountBalanceToken.BSC_BUSD_TESTNET]: TokenSymbols.BUSD,
	[WalletAccountBalanceToken.BSC_BNB]: TokenSymbols.BNB,
	[WalletAccountBalanceToken.POLYGON_USDT]: TokenSymbols.USDT,
	[WalletAccountBalanceToken.POLYGON_USDT_TESTNET]: TokenSymbols.USDT,
	[WalletAccountBalanceToken.POLYGON_USDC]: TokenSymbols.USDC,
	[WalletAccountBalanceToken.POLYGON_USDC_TESTNET]: TokenSymbols.USDC,
	[WalletAccountBalanceToken.POLYGON_BUSD]: TokenSymbols.BUSD,
	[WalletAccountBalanceToken.POLYGON_BUSD_TESTNET]: TokenSymbols.BUSD,
	[WalletAccountBalanceToken.POLYGON_MATIC]: TokenSymbols.MATIC,
}

export const tokenCoinPrices = {
	[WalletAccountBalanceToken.NOT_DEFINED]: CoinPriceSymbols.USDT,
	[WalletAccountBalanceToken.NEAR_USDTT]: CoinPriceSymbols.USDT,
	[WalletAccountBalanceToken.NEAR_USDTT_TESTNET]: CoinPriceSymbols.USDT,
	[WalletAccountBalanceToken.NEAR_USDT]: CoinPriceSymbols.USDT,
	[WalletAccountBalanceToken.NEAR_USDT_TESTNET]: CoinPriceSymbols.USDT,
	[WalletAccountBalanceToken.NEAR_USDC]: CoinPriceSymbols.USDC,
	[WalletAccountBalanceToken.NEAR_USDC_TESTNET]: CoinPriceSymbols.USDC,
	[WalletAccountBalanceToken.WRAP_NEAR]: CoinPriceSymbols.NEAR,
	[WalletAccountBalanceToken.WRAP_NEAR_TESTNET]: CoinPriceSymbols.NEAR,
	[WalletAccountBalanceToken.NEAR]: CoinPriceSymbols.NEAR,
	[WalletAccountBalanceToken.USD_NEAR]: CoinPriceSymbols.NEAR,
	[WalletAccountBalanceToken.ETH]: CoinPriceSymbols.ETH,
	[WalletAccountBalanceToken.WRAP_ETH]: CoinPriceSymbols.ETH,
	[WalletAccountBalanceToken.WRAP_ETH_SEPOLIA]: CoinPriceSymbols.ETH,
	[WalletAccountBalanceToken.ETH_USDC]: CoinPriceSymbols.USDC,
	[WalletAccountBalanceToken.ETH_USDC_SEPOLIA]: CoinPriceSymbols.USDC,
	[WalletAccountBalanceToken.ETH_USDT]: CoinPriceSymbols.USDT,
	[WalletAccountBalanceToken.ETH_USDT_SEPOLIA]: CoinPriceSymbols.USDT,
	[WalletAccountBalanceToken.ETH_BUSD]: CoinPriceSymbols.BUSD,
	[WalletAccountBalanceToken.ETH_BUSD_SEPOLIA]: CoinPriceSymbols.BUSD,
	[WalletAccountBalanceToken.ETH_GUSD]: CoinPriceSymbols.GUSD,
	[WalletAccountBalanceToken.ETH_GUSD_SEPOLIA]: CoinPriceSymbols.GUSD,
	[WalletAccountBalanceToken.TRON]: CoinPriceSymbols.TRON,
	[WalletAccountBalanceToken.WRAP_TRON]: CoinPriceSymbols.TRON,
	[WalletAccountBalanceToken.WRAP_TRON_NILE]: CoinPriceSymbols.TRON,
	[WalletAccountBalanceToken.TRON_USDT]: CoinPriceSymbols.USDT,
	[WalletAccountBalanceToken.TRON_USDT_NILE]: CoinPriceSymbols.USDT,
	[WalletAccountBalanceToken.BSC_USDT]: CoinPriceSymbols.USDT,
	[WalletAccountBalanceToken.BSC_USDT_TESTNET]: CoinPriceSymbols.USDT,
	[WalletAccountBalanceToken.BSC_USDC]: CoinPriceSymbols.USDC,
	[WalletAccountBalanceToken.BSC_USDC_TESTNET]: CoinPriceSymbols.USDC,
	[WalletAccountBalanceToken.BSC_BUSD]: CoinPriceSymbols.BUSD,
	[WalletAccountBalanceToken.BSC_BUSD_TESTNET]: CoinPriceSymbols.BUSD,
	[WalletAccountBalanceToken.POLYGON_USDT]: CoinPriceSymbols.USDT,
	[WalletAccountBalanceToken.POLYGON_USDT_TESTNET]: CoinPriceSymbols.USDT,
	[WalletAccountBalanceToken.POLYGON_USDC]: CoinPriceSymbols.USDC,
	[WalletAccountBalanceToken.POLYGON_USDC_TESTNET]: CoinPriceSymbols.USDC,
	[WalletAccountBalanceToken.POLYGON_BUSD]: CoinPriceSymbols.BUSD,
	[WalletAccountBalanceToken.POLYGON_BUSD_TESTNET]: CoinPriceSymbols.BUSD,
	[WalletAccountBalanceToken.BSC_BNB]: CoinPriceSymbols.BSC,
	[WalletAccountBalanceToken.POLYGON_MATIC]: CoinPriceSymbols.POLYGON,
}

export const allStatuses = [
	'draft',
	'inReview',
	'pending',
	'readyToPay',
	'paid',
	'closed',
	'void',
] as IStatusType[]

export const getCustomInvoiceId = (invoice: Pick<IInvoice, 'id' | 'invoice_id' | 'custom_invoice_id'>) => {
	if (typeof invoice.custom_invoice_id === 'string') {
		const customId = invoice.custom_invoice_id

		return customId.length > 10
			? `${customId.slice(0, 6)}…${customId.slice(-4)}`
			: customId
	}

	return invoice.invoice_id
}

export const isInvoiceEditable = (
	invoice: Pick<IInvoice, 'author' | 'receiver'>,
	user: IUser,
	userTeamRoles: TeamRoleEnumType[]
) => invoice.author === user.id
		|| (invoice.receiver?.id !== invoice.author
			&& user.role === RoleEnumType.COMPANY
			&& hasTeamUserRoles(userTeamRoles, [TeamRoleEnumType.TEAM_OWNER, TeamRoleEnumType.TEAM_ADMIN]))

export const getInvoiceToken = (token: WalletAccountBalanceToken) => {
	switch (token) {
		case WalletAccountBalanceToken.USD_NEAR:
			return WalletAccountBalanceToken.NEAR
		default:
			return token
	}
}

export const getInvoiceTotalAmount = (invoice: IInvoice) => isFiatCurrencyToken(invoice.token)
	? parseFloat(
		(invoice.total_amount / invoice.token_price).toFixed(5)
	)
	: invoice.total_amount
