import { QuestionMarkRounded } from '@mui/icons-material';
import selectWalletIcon
	from '@near-wallet-selector/sender/assets/sender-icon.png'
import binanceWalletIcon from 'assets/binance-wallet.png'
import busdIcon from 'assets/binance.svg'
import bscWalletIcon from 'assets/bsc-wallet-icon.svg'
import ethWalletIcon from 'assets/eth-wallet-icon.svg'
import gusdIcon from 'assets/gusd.svg'
import metaMaskWalletIcon from 'assets/metamask-wallet.png'
import nearWalletIcon from 'assets/near-wallet-icon.svg'
import polygonWalletIcon from 'assets/polygon-wallet-icon.svg'
import tronWalletIcon from 'assets/tron-wallet-icon.svg'
import tronlinkIcon from 'assets/tronlink.png'
import ledgerIcon from 'assets/ledger.png'
import senderIcon from 'assets/sender.png'

import usdIcon from 'assets/usd-icon.svg'
import usdcIcon from 'assets/usdc.svg'
import usdtIcon from 'assets/usdt.svg'

import wNearIcon from 'assets/wnear.svg'
import { connectTron } from 'components/Wallet/components/TronProtocolWallet'

import app_config from 'config/app_config'
import { BigNumber, ethers } from 'ethers'
import { type Dispatch } from 'react'

import { ProtocolEnumType, WalletEnumType } from 'redux/invoice/types'
import { onOpenNotification } from 'redux/notification/reducer'
import { IUser, RoleEnumType, SubscriptionEnumType } from 'redux/user/types'
import { setWalletAccount } from 'redux/walletAccount/reducer'

import {
	IAccountState,
	TokenSymbols,
	WalletAccountBalances,
	WalletAccountBalanceToken,
} from 'redux/walletAccount/types'
import {
	formatCryptoAmount,
	GUSD_EXPONENT,
	USD_EXPONENT,
} from 'utils/amountParser'
import { tokenSymbols } from 'utils/invoiceUtils'
import { getBalance, getFTBalance } from 'utils/nearUtils'
import { tronBalanceAbi } from 'utils/tron/abi'
import { sleep, TRON_REFRESH_TIMEOUT } from 'utils/userUtils'
import { BSC_WALLET, USER_PROTOCOL } from '../constants/localStorageKeys'
import { CoinPriceSymbols } from '../redux/coinPrices/types'

export interface ITronTransactionInfo {
	fee?: number
	result: string
	receipt: {
		energy_fee: number
		energy_usage_total: number
		net_fee: number
	}
}

export interface ITronTransaction {
	send: (params?: any) => Promise<string>
}

interface IEthTokenContract {
	token: WalletAccountBalanceToken
	contract: ethers.Contract
}

interface IGroupItem {
	label?: string
	icon?: string
	tooltip?: string
}

interface IBlockchainGroupItem extends IGroupItem {
	value: ProtocolEnumType
}

interface ICurrencyGroupItem extends IGroupItem {
	value: WalletAccountBalanceToken | undefined
}

interface IRoleGroupItem extends IGroupItem {
	value: RoleEnumType
}

interface IBscWalletGroupItem extends IGroupItem {
	value: WalletEnumType
}

interface ISubscriptionGroupItem extends IGroupItem {
	value: SubscriptionEnumType
}

interface IWalletGroupItem extends IGroupItem {
	value: string
}

export type IRoleGroup = IRoleGroupItem[]
export type ISubscriptionGroup = ISubscriptionGroupItem[]
export type IBlockchainGroup = IBlockchainGroupItem[]
export type ICurrencyGroup = ICurrencyGroupItem[]
export type IBscWalletGroup = IBscWalletGroupItem[]
export type IWalletGroup = IWalletGroupItem[]

type IWalletName = 'near-wallet' | 'sender' | 'ledger'

export interface IWalletInfo {
	name: IWalletName
	walletName: string
	walletIcon: string
}

export const TRANSACTION_FAILED = 'Transaction failed'

export const ERC20Protocols = [
	ProtocolEnumType.ETH,
	ProtocolEnumType.BSC,
	ProtocolEnumType.POLYGON,
]

export const walletList: IWalletInfo[] = [
	{
		name: 'near-wallet',
		walletName: WalletEnumType.NEAR_WALLET,
		walletIcon: nearWalletIcon,
	},
	{
		name: 'sender',
		walletName: WalletEnumType.SENDER_WALLET,
		walletIcon: selectWalletIcon,
	},
]

export function getProtocolTitleForSwapin(protocol: string) {
	switch (protocol) {
		case ProtocolEnumType.TRON:
			return 'TRC20'
		default:
			return 'ERC20'
	}
}

export function getCryptoCurrencyCodeForSwapin(protocol: string) {
	switch (protocol) {
		case ProtocolEnumType.BSC:
			return 'BTC'
		case ProtocolEnumType.ETH:
			return 'USDT.ERC20'
		default:
			return 'USDT.TRC20'
	}
}

export function getCryptoPrettyCurrencyCodeForSwapin(protocol: string) {
	switch (protocol) {
		case ProtocolEnumType.BSC:
			return 'BNB'
		default:
			return 'USDT'
	}
}

export function getProtocolTitle(protocol: string) {
	switch (protocol) {
		case ProtocolEnumType.NEAR:
			return 'Near'
		case ProtocolEnumType.ETH:
			return 'Ethereum ERC-20'
		case ProtocolEnumType.TRON:
			return 'Tron TRC-20'
		case ProtocolEnumType.BSC:
			return 'Binance ERC-20'
		case ProtocolEnumType.POLYGON:
			return 'Polygon ERC-20'
		default:
			return ''
	}
}

export function getShortProtocolTitle(protocol: string) {
	switch (protocol) {
		case ProtocolEnumType.NEAR:
			return 'Near'
		case ProtocolEnumType.ETH:
			return 'Ethereum'
		case ProtocolEnumType.TRON:
			return 'Tron'
		case ProtocolEnumType.BSC:
			return 'Binance'
		case ProtocolEnumType.POLYGON:
			return 'Polygon'
		default:
			return ''
	}
}

export function getShortNetworkTitle(protocol: string) {
	switch (protocol) {
		case ProtocolEnumType.NEAR:
			return 'Near'
		case ProtocolEnumType.TRON:
			return 'TRC-20'
		default:
			return 'ERC-20'
	}
}

export function getWalletIcon(wallet: WalletEnumType) {
	switch (wallet) {
		case WalletEnumType.METAMASK_WALLET:
			return metaMaskWalletIcon
		case WalletEnumType.BINANCE_WALLET:
			return binanceWalletIcon
		case WalletEnumType.TRONLINK_WALLET:
			return tronlinkIcon
		case WalletEnumType.LEDGER_WALLET:
			return ledgerIcon
		case WalletEnumType.SENDER_WALLET:
			return senderIcon
		case WalletEnumType.NEAR_WALLET:
			return nearWalletIcon
		default:
			return QuestionMarkRounded
	}
}

export const blockchainListShort: IBlockchainGroup = [
	{
		value: ProtocolEnumType.NEAR,
		label: getShortProtocolTitle(ProtocolEnumType.NEAR),
		icon: nearWalletIcon,
	},
	{
		value: ProtocolEnumType.ETH,
		label: getShortProtocolTitle(ProtocolEnumType.ETH),
		icon: ethWalletIcon,
	},
	{
		value: ProtocolEnumType.BSC,
		label: getShortProtocolTitle(ProtocolEnumType.BSC),
		icon: bscWalletIcon,
	},
	{
		value: ProtocolEnumType.POLYGON,
		label: getShortProtocolTitle(ProtocolEnumType.POLYGON),
		icon: polygonWalletIcon,
	},
	{
		value: ProtocolEnumType.TRON,
		label: getShortProtocolTitle(ProtocolEnumType.TRON),
		icon: tronWalletIcon,
	},
]

export const blockchainListTiny: IBlockchainGroup = [
	{
		value: ProtocolEnumType.NEAR,
		tooltip: getProtocolTitle(ProtocolEnumType.NEAR),
		icon: nearWalletIcon,
	},
	{
		value: ProtocolEnumType.ETH,
		tooltip: getProtocolTitle(ProtocolEnumType.ETH),
		icon: ethWalletIcon,
	},
	{
		value: ProtocolEnumType.BSC,
		tooltip: getProtocolTitle(ProtocolEnumType.BSC),
		icon: bscWalletIcon,
	},
	{
		value: ProtocolEnumType.POLYGON,
		tooltip: getProtocolTitle(ProtocolEnumType.POLYGON),
		icon: polygonWalletIcon,
	},
	{
		value: ProtocolEnumType.TRON,
		tooltip: getProtocolTitle(ProtocolEnumType.TRON),
		icon: tronWalletIcon,
	},
]

export const blockchainListLong: IBlockchainGroup = [
	{
		value: ProtocolEnumType.NEAR,
		label: getProtocolTitle(ProtocolEnumType.NEAR),
		icon: nearWalletIcon,
	},
	{
		value: ProtocolEnumType.ETH,
		label: getProtocolTitle(ProtocolEnumType.ETH),
		icon: ethWalletIcon,
	},
	{
		value: ProtocolEnumType.BSC,
		label: getProtocolTitle(ProtocolEnumType.BSC),
		icon: bscWalletIcon,
	},
	{
		value: ProtocolEnumType.POLYGON,
		label: getProtocolTitle(ProtocolEnumType.POLYGON),
		icon: polygonWalletIcon,
	},
	{
		value: ProtocolEnumType.TRON,
		label: getProtocolTitle(ProtocolEnumType.TRON),
		icon: tronWalletIcon,
	},
]

export const roleList: IRoleGroup = [
	{
		value: RoleEnumType.COMPANY,
		label: 'Company',
	},
	{
		value: RoleEnumType.CONTRACTOR,
		label: 'Contractor',
	},
]

export const signUpRoleList: IRoleGroup = [
	{
		value: RoleEnumType.COMPANY,
		label: 'I’M A COMPANY',
	},
	{
		value: RoleEnumType.CONTRACTOR,
		label: 'I’M A CONTRACTOR',
	},
]

export const blockchainListAll: IBlockchainGroup = [
	...blockchainListShort,
	{
		value: ProtocolEnumType.NOT_DEFINED,
		label: 'All',
	},
]

export const currencyListNear: ICurrencyGroup = [
	{
		value: app_config.env
			.REACT_APP_NEAR_USDTT_TOKEN as WalletAccountBalanceToken,
		label: `Tether USD (${TokenSymbols.NEAR_USDTT})`,
		icon: usdtIcon,
	},
	{
		value: app_config.env
			.REACT_APP_NEAR_USDT_TOKEN as WalletAccountBalanceToken,
		label: `Tether USD (${TokenSymbols.NEAR_USDT})`,
		icon: usdtIcon,
	},
	{
		value: app_config.env
			.REACT_APP_NEAR_USDC_TOKEN as WalletAccountBalanceToken,
		label: `USD Coin (${TokenSymbols.NEAR_USDC})`,
		icon: usdcIcon,
	},
	{
		value: WalletAccountBalanceToken.NEAR,
		label: TokenSymbols.NEAR,
		icon: wNearIcon,
	},
	{
		value: WalletAccountBalanceToken.USD_NEAR,
		label: `${TokenSymbols.USD} to ${TokenSymbols.NEAR}`,
		icon: usdIcon,
	},
]

export const currencyListEther: ICurrencyGroup = [
	{
		value: app_config.env.REACT_APP_ETH_USDT_TOKEN as WalletAccountBalanceToken,
		label: `Tether USD (${TokenSymbols.USDT})`,
		icon: usdtIcon,
	},
	{
		value: app_config.env.REACT_APP_ETH_USDC_TOKEN as WalletAccountBalanceToken,
		label: `USD Coin (${TokenSymbols.USDC})`,
		icon: usdcIcon,
	},
	{
		value: app_config.env.REACT_APP_ETH_BUSD_TOKEN as WalletAccountBalanceToken,
		label: `Binance USD (${TokenSymbols.BUSD})`,
		icon: busdIcon,
	},
	{
		value: app_config.env.REACT_APP_ETH_GUSD_TOKEN as WalletAccountBalanceToken,
		label: `Gemini Dollar (${TokenSymbols.GUSD})`,
		icon: gusdIcon,
	},
	{
		value: app_config.env.REACT_APP_ETH_TOKEN as WalletAccountBalanceToken,
		label: `Wrapped Ether (${TokenSymbols.WRAPPED_ETH})`,
		icon: ethWalletIcon,
	},
]

export const currencyListBsc: ICurrencyGroup = [
	{
		value: app_config.env.REACT_APP_BSC_USDT_TOKEN as WalletAccountBalanceToken,
		label: `Tether USD (${TokenSymbols.USDT})`,
		icon: usdtIcon,
	},
	{
		value: app_config.env.REACT_APP_BSC_USDC_TOKEN as WalletAccountBalanceToken,
		label: `USD Coin (${TokenSymbols.USDC})`,
		icon: usdcIcon,
	},
	{
		value: app_config.env.REACT_APP_BSC_BUSD_TOKEN as WalletAccountBalanceToken,
		label: `Binance USD (${TokenSymbols.BUSD})`,
		icon: busdIcon,
	},
]

export const currencyListPolygon: ICurrencyGroup = [
	{
		value: app_config.env.REACT_APP_POLYGON_USDT_TOKEN as WalletAccountBalanceToken,
		label: `Tether USD (${TokenSymbols.USDT})`,
		icon: usdtIcon,
	},
	{
		value: app_config.env.REACT_APP_POLYGON_USDC_TOKEN as WalletAccountBalanceToken,
		label: `USD Coin (${TokenSymbols.USDC})`,
		icon: usdcIcon,
	},
	{
		value: app_config.env.REACT_APP_POLYGON_BUSD_TOKEN as WalletAccountBalanceToken,
		label: `Binance USD (${TokenSymbols.BUSD})`,
		icon: busdIcon,
	},
]

export const currencyListTron: ICurrencyGroup = [
	{
		value: app_config.env
			.REACT_APP_TRON_USDT_TOKEN as WalletAccountBalanceToken,
		label: `Tether USD (${TokenSymbols.USDT})`,
		icon: usdtIcon,
	},
	{
		value: app_config.env.REACT_APP_TRON_TOKEN as WalletAccountBalanceToken,
		label: `Wrapped Tron (${TokenSymbols.WRAPPED_TRON})`,
		icon: tronWalletIcon,
	},
]

export const currencyListNearShortLabels: ICurrencyGroup = [
	{
		value: app_config.env
			.REACT_APP_NEAR_USDTT_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.NEAR_USDTT,
		icon: usdtIcon,
	},
	{
		value: app_config.env
			.REACT_APP_NEAR_USDT_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.NEAR_USDT,
		icon: usdtIcon,
	},
	{
		value: app_config.env
			.REACT_APP_NEAR_USDC_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.NEAR_USDC,
		icon: usdcIcon,
	},
	{
		value: WalletAccountBalanceToken.NEAR,
		label: TokenSymbols.NEAR,
		icon: wNearIcon,
	},
	{
		value: WalletAccountBalanceToken.USD_NEAR,
		label: TokenSymbols.USD,
		icon: usdIcon,
	},
]

export const currencyListEtherShortLabels: ICurrencyGroup = [
	{
		value: app_config.env.REACT_APP_ETH_USDT_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.USDT,
		icon: usdtIcon,
	},
	{
		value: app_config.env.REACT_APP_ETH_USDC_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.USDC,
		icon: usdcIcon,
	},
	{
		value: app_config.env.REACT_APP_ETH_BUSD_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.BUSD,
		icon: busdIcon,
	},
	{
		value: app_config.env.REACT_APP_ETH_GUSD_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.GUSD,
		icon: gusdIcon,
	},
	{
		value: app_config.env.REACT_APP_ETH_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.WRAPPED_ETH,
		icon: ethWalletIcon,
	},
]

export const currencyListBscShortLabels: ICurrencyGroup = [
	{
		value: app_config.env.REACT_APP_BSC_USDT_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.USDT,
		icon: usdtIcon,
	},
	{
		value: app_config.env.REACT_APP_BSC_USDC_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.USDC,
		icon: usdcIcon,
	},
	{
		value: app_config.env.REACT_APP_BSC_BUSD_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.BUSD,
		icon: busdIcon,
	},
]

export const currencyListPolygonShortLabels: ICurrencyGroup = [
	{
		value: app_config.env.REACT_APP_POLYGON_USDT_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.USDT,
		icon: usdtIcon,
	},
	{
		value: app_config.env.REACT_APP_POLYGON_USDC_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.USDC,
		icon: usdcIcon,
	},
	{
		value: app_config.env.REACT_APP_POLYGON_BUSD_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.BUSD,
		icon: busdIcon,
	},
]

export const currencyListTronShortLabels: ICurrencyGroup = [
	{
		value: app_config.env
			.REACT_APP_TRON_USDT_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.USDT,
		icon: usdtIcon,
	},
	{
		value: app_config.env.REACT_APP_TRON_TOKEN as WalletAccountBalanceToken,
		label: TokenSymbols.WRAPPED_TRON,
		icon: tronWalletIcon,
	},
]

export const currencyListNearFilters: ICurrencyGroup = [
	{
		value: WalletAccountBalanceToken.NOT_DEFINED,
		label: 'All',
	},
	...currencyListNearShortLabels
]

export const currencyListEtherFilters: ICurrencyGroup = [
	{
		value: WalletAccountBalanceToken.NOT_DEFINED,
		label: 'All',
	},
	...currencyListEtherShortLabels,
]

export const currencyListBscFilters: ICurrencyGroup = [
	{
		value: WalletAccountBalanceToken.NOT_DEFINED,
		label: 'All',
	},
	...currencyListBscShortLabels,
]

export const currencyListPolygonFilters: ICurrencyGroup = [
	{
		value: WalletAccountBalanceToken.NOT_DEFINED,
		label: 'All',
	},
	...currencyListPolygonShortLabels,
]

export const currencyListTronFilters: ICurrencyGroup = [
	{
		value: WalletAccountBalanceToken.NOT_DEFINED,
		label: 'All',
	},
	...currencyListTronShortLabels,
]

export const bscWalletList: IBscWalletGroup = [
	{
		value: WalletEnumType.BINANCE_WALLET,
		label: 'Binance',
		icon: binanceWalletIcon,
	},
	{
		value: WalletEnumType.METAMASK_WALLET,
		label: 'Metamask',
		icon: metaMaskWalletIcon,
	},
]

export const protocolIcons = {
	[ProtocolEnumType.ETH]: ethWalletIcon,
	[ProtocolEnumType.NEAR]: nearWalletIcon,
	[ProtocolEnumType.TRON]: tronWalletIcon,
	[ProtocolEnumType.BSC]: bscWalletIcon,
	[ProtocolEnumType.POLYGON]: polygonWalletIcon,
	[ProtocolEnumType.NOT_DEFINED]: '',
}

export const getProtocolCurrency = (protocol: ProtocolEnumType) => {
	switch (protocol) {
		case ProtocolEnumType.POLYGON:
			return 'MATIC'
		case ProtocolEnumType.BSC:
			return 'BNB'
		case ProtocolEnumType.TRON:
			return 'TRX'
		default:
			return protocol.toUpperCase()
	}
}

export const isFiatCurrencyToken = (token: WalletAccountBalanceToken) => {
	switch (token) {
		case WalletAccountBalanceToken.USD_NEAR:
			return true
		default:
			return false
	}
}

export const getCoinPriceSymbolForProtocol = (protocol: ProtocolEnumType): CoinPriceSymbols => {
	switch (protocol) {
		case ProtocolEnumType.POLYGON:
			return CoinPriceSymbols.POLYGON
		case ProtocolEnumType.BSC:
			return CoinPriceSymbols.BSC
		case ProtocolEnumType.ETH:
			return CoinPriceSymbols.ETH
		case ProtocolEnumType.TRON:
			return CoinPriceSymbols.TRON
		default:
			return CoinPriceSymbols.NEAR
	}
}

export const getEthTokenContracts = (
	provider: ethers.providers.Web3Provider
): IEthTokenContract[] =>
	[
		app_config.env.REACT_APP_ETH_USDT_TOKEN,
		app_config.env.REACT_APP_ETH_USDC_TOKEN,
		app_config.env.REACT_APP_ETH_BUSD_TOKEN,
		app_config.env.REACT_APP_ETH_GUSD_TOKEN,
		app_config.env.REACT_APP_ETH_TOKEN,
	].map(
		(token): IEthTokenContract => ({
			token: token as WalletAccountBalanceToken,
			contract: new ethers.Contract(
				token,
				['function balanceOf(address) view returns (uint)'],
				provider
			),
		})
	)

export const getBscTokenContracts = (
	provider: ethers.providers.Web3Provider
): IEthTokenContract[] =>
	[
		app_config.env.REACT_APP_BSC_USDT_TOKEN,
		app_config.env.REACT_APP_BSC_USDC_TOKEN,
		app_config.env.REACT_APP_BSC_BUSD_TOKEN,
	].map(
		(token): IEthTokenContract => ({
			token: token as WalletAccountBalanceToken,
			contract: new ethers.Contract(
				token,
				['function balanceOf(address) view returns (uint)'],
				provider
			),
		})
	)

export const getPolygonTokenContracts = (
	provider: ethers.providers.Web3Provider
): IEthTokenContract[] =>
	[
		app_config.env.REACT_APP_POLYGON_USDT_TOKEN,
		app_config.env.REACT_APP_POLYGON_USDC_TOKEN,
		app_config.env.REACT_APP_POLYGON_BUSD_TOKEN,
	].map(
		(token): IEthTokenContract => ({
			token: token as WalletAccountBalanceToken,
			contract: new ethers.Contract(
				token,
				['function balanceOf(address) view returns (uint)'],
				provider
			),
		})
	)

export const isBscBinanceWalletSelected = (protocol: ProtocolEnumType) => {
	if (protocol === ProtocolEnumType.BSC) {
		const currentWallet = localStorage.getItem(BSC_WALLET) ?? WalletEnumType.BINANCE_WALLET

		return currentWallet === WalletEnumType.BINANCE_WALLET
	}

	return false
}

export const getWeb3Provider = (protocol: ProtocolEnumType, ethereum: any) => (
	new ethers.providers.Web3Provider(
		isBscBinanceWalletSelected(protocol)
			? window.BinanceChain
			: ethereum,
	)
)

export const updateMetaMaskTokensBalance = (
	ethereum: any,
	account: string,
	protocol: ProtocolEnumType,
	dispatch: Dispatch<{ payload: any }>
) => {
	const provider = getWeb3Provider(protocol, ethereum)
	const contracts = protocol === ProtocolEnumType.ETH
		? getEthTokenContracts(provider)
		: protocol === ProtocolEnumType.POLYGON
			? getPolygonTokenContracts(provider)
			: getBscTokenContracts(provider)

	ethereum.request({
		method: 'eth_getBalance',
		params: [account],
	}).then((balance: string) => {
		const nativeBalanceToken = protocol === ProtocolEnumType.ETH
			? WalletAccountBalanceToken.ETH
			: protocol === ProtocolEnumType.POLYGON
				? WalletAccountBalanceToken.POLYGON_MATIC
				: WalletAccountBalanceToken.BSC_BNB

		dispatch(
			setWalletAccount({
				walletBalances: {
					[nativeBalanceToken]: ethers.utils.formatEther(balance),
				}
			})
		)
	}).catch(() => {})

	contracts.forEach(({ contract, token }) => {
		contract.balanceOf(account)
			.then((balance: BigNumber) => {
				dispatch(
					setWalletAccount({
						walletBalances: {
							[token]: [
								app_config.env.REACT_APP_ETH_BUSD_TOKEN,
								app_config.env.REACT_APP_ETH_TOKEN,
								app_config.env.REACT_APP_BSC_USDT_TOKEN,
								app_config.env.REACT_APP_BSC_USDC_TOKEN,
								app_config.env.REACT_APP_BSC_BUSD_TOKEN,
								app_config.env.REACT_APP_POLYGON_BUSD_TOKEN,
							].includes(token)
								? ethers.utils.formatEther(balance)
								: token === app_config.env.REACT_APP_ETH_GUSD_TOKEN
									? ethers.utils.formatUnits(balance, GUSD_EXPONENT)
									: ethers.utils.formatUnits(balance, USD_EXPONENT),
						},
					}),
				)
			})
			.catch(() => {
				dispatch(
					onOpenNotification({
						message: `Please make sure you have selected ${protocol?.toUpperCase()} protocol network in your wallet extension`,
						notificationType: 'warning',
						preventDuplicate: true,
					})
				)

				dispatch(
					setWalletAccount({
						walletBalances: {
							[token]: '0.00',
						},
					}),
				)
			})
	})
}

export const updateTronTokensBalance = (
	dispatch: Dispatch<{ payload: IAccountState }>
) => {
	window.tronWeb.trx
		.getBalance(window.tronWeb.defaultAddress.base58)
		.then((balance: number) => {
			dispatch(
				setWalletAccount({
					walletBalances: {
						[WalletAccountBalanceToken.TRON]: ethers.utils.formatUnits(balance, USD_EXPONENT),
					},
				})
			)
		})
		.catch(() => {})

	;[
		app_config.env.REACT_APP_TRON_USDT_TOKEN,
		app_config.env.REACT_APP_TRON_TOKEN,
	].forEach(async (token) => {
		const contract = await window.tronWeb.contract(tronBalanceAbi, token)

		contract
			.balanceOf(window.tronWeb.defaultAddress.base58)
			.call()
			.then((balance: BigNumber) => {
				dispatch(
					setWalletAccount({
						walletBalances: {
							[token]: ethers.utils.formatUnits(balance, USD_EXPONENT),
						},
					})
				)
			})
			.catch(() => {})
	})
}

export const getTronTransactionInfo = async (
	tx: string
): Promise<ITronTransactionInfo> => {
	const txInfo = await window.tronWeb.trx.getTransactionInfo(tx)

	if (!txInfo.result && !txInfo.receipt) {
		await sleep(TRON_REFRESH_TIMEOUT)

		return getTronTransactionInfo(tx)
	}

	if (txInfo.result && txInfo.result !== 'SUCCESS') {
		throw new Error(TRANSACTION_FAILED)
	}

	return txInfo
}

export const updateNearTokensBalance = (
	account: string,
	dispatch: Dispatch<{ payload: IAccountState }>
) => {
	;[
		app_config.env.REACT_APP_NEAR_USDTT_TOKEN,
		app_config.env.REACT_APP_NEAR_USDT_TOKEN,
		app_config.env.REACT_APP_NEAR_USDC_TOKEN,
		app_config.env.REACT_APP_NEAR_TOKEN,
		WalletAccountBalanceToken.NEAR,
	].forEach((token) => {
		;(token === WalletAccountBalanceToken.NEAR
				? getBalance(account)
				: getFTBalance(token, account)
		).then((balance) => {
			dispatch(
				setWalletAccount({
					walletBalances: {
						[token]: formatCryptoAmount(
							balance,
							token as WalletAccountBalanceToken
						),
					},
				})
			)
		})
	})
}

export const getCurrenciesByProtocol = (
	protocol: ProtocolEnumType,
	isFilters = false,
	isShortLabels = false
) => {
	switch (protocol) {
		case ProtocolEnumType.TRON:
			return isShortLabels
				? currencyListTronShortLabels
				: isFilters
					? currencyListTronFilters
					: currencyListTron
		case ProtocolEnumType.ETH:
			return isShortLabels
				? currencyListEtherShortLabels
				: isFilters
					? currencyListEtherFilters
					: currencyListEther
		case ProtocolEnumType.BSC:
			return isShortLabels
				? currencyListBscShortLabels
				: isFilters
					? currencyListBscFilters
					: currencyListBsc
		case ProtocolEnumType.POLYGON:
			return isShortLabels
				? currencyListPolygonShortLabels
				: isFilters
					? currencyListPolygonFilters
					: currencyListPolygon
		default:
			return isShortLabels
				? currencyListNearShortLabels
				: isFilters
					? currencyListNearFilters
					: currencyListNear
	}
}

export const getDefaultTokenByProtocol = (
	protocol: ProtocolEnumType
): WalletAccountBalanceToken => {
	switch (protocol) {
		case ProtocolEnumType.TRON:
			return app_config.env
				.REACT_APP_TRON_USDT_TOKEN as WalletAccountBalanceToken
		case ProtocolEnumType.ETH:
			return app_config.env
				.REACT_APP_ETH_USDT_TOKEN as WalletAccountBalanceToken
		case ProtocolEnumType.BSC:
			return app_config.env
				.REACT_APP_BSC_USDT_TOKEN as WalletAccountBalanceToken
		case ProtocolEnumType.POLYGON:
			return app_config.env
				.REACT_APP_POLYGON_USDT_TOKEN as WalletAccountBalanceToken
		default:
			return app_config.env
				.REACT_APP_NEAR_USDTT_TOKEN as WalletAccountBalanceToken
	}
}

export const getNativeAccountTokenByProtocol = (
	protocol: ProtocolEnumType
): WalletAccountBalanceToken => {
	switch (protocol) {
		case ProtocolEnumType.TRON:
			return WalletAccountBalanceToken.TRON
		case ProtocolEnumType.ETH:
			return WalletAccountBalanceToken.ETH
		case ProtocolEnumType.BSC:
			return WalletAccountBalanceToken.BSC_BNB
		case ProtocolEnumType.POLYGON:
			return WalletAccountBalanceToken.POLYGON_MATIC
		default:
			return WalletAccountBalanceToken.NEAR
	}
}

export const getTransactionSiteByProtocol = (protocol: ProtocolEnumType) => {
	switch (protocol) {
		case ProtocolEnumType.TRON:
			return app_config.env.REACT_APP_TRON_TRANSACTIONS_SITE
		case ProtocolEnumType.ETH:
			return app_config.env.REACT_APP_ETH_TRANSACTIONS_SITE
		case ProtocolEnumType.BSC:
			return app_config.env.REACT_APP_BSC_TRANSACTIONS_SITE
		case ProtocolEnumType.POLYGON:
			return app_config.env.REACT_APP_POLYGON_TRANSACTIONS_SITE
		default:
			return app_config.env.REACT_APP_TRANSACTIONS_SITE
	}
}

export const getErcChainIdByProtocol = (protocol: ProtocolEnumType) => {
	switch (protocol) {
		case ProtocolEnumType.BSC:
			return app_config.env.REACT_APP_BSC_CHAIN_ID
		case ProtocolEnumType.POLYGON:
			return app_config.env.REACT_APP_POLYGON_CHAIN_ID
		default:
			return app_config.env.REACT_APP_ETH_CHAIN_ID
	}
}

export const onCatchForWalletExtension = (
	dispatch: any,
	error: any | undefined = undefined,
	notificationType: 'error' | 'success' | 'warning' | 'info' | null = 'warning'
) => {
	if (error) {
		dispatch(
			onOpenNotification({
				message: error?.reason ?? error?.message ?? error?.error ?? error,
				notificationType,
			})
		)
	} else {
		dispatch(
			onOpenNotification({
				message: 'Something went wrong',
				notificationType,
			})
		)
	}
}

export const getMetaMaskContractByProtocol = (protocol: ProtocolEnumType) => {
	switch (protocol) {
		case ProtocolEnumType.ETH:
			return app_config.env.REACT_APP_ETH_CONTRACT
		case ProtocolEnumType.BSC:
			return app_config.env.REACT_APP_BSC_CONTRACT
		default:
			return app_config.env.REACT_APP_POLYGON_CONTRACT
	}
}

export const getWalletNameByProtocol = (protocol: ProtocolEnumType, walletName: string = WalletEnumType.UNKNOWN_WALLET) => {
	switch (protocol) {
		case ProtocolEnumType.NEAR:
			return walletName
		case ProtocolEnumType.TRON:
			return WalletEnumType.TRONLINK_WALLET
		case ProtocolEnumType.BSC:
			return isBscBinanceWalletSelected(protocol)
				? WalletEnumType.BINANCE_WALLET
				: WalletEnumType.METAMASK_WALLET
		default:
			return WalletEnumType.METAMASK_WALLET
	}
}

export const getWalletIconByProtocol = (protocol: ProtocolEnumType, walletIcon: string = nearWalletIcon) => {
	switch (protocol) {
		case ProtocolEnumType.TRON:
			return tronWalletIcon
		case ProtocolEnumType.ETH:
			return ethWalletIcon
		case ProtocolEnumType.BSC:
			return bscWalletIcon
		case ProtocolEnumType.POLYGON:
			return polygonWalletIcon
		default:
			return walletIcon
	}
}

export const getWalletExtensionNotAvailableMessage = (wallet: WalletEnumType) =>
	`Please install or open ${wallet}. Unlock the extension, reload the page and try again`

export const getUserProtocol = (user: IUser) => localStorage.getItem(USER_PROTOCOL) ?? user.protocol

export const connectWalletByProtocol = async (
	protocol: ProtocolEnumType,
	status: string,
	user: IUser,
	dispatch: any,
	modal: any,
	connect: any,
	bscWallet: any
) => {
	if (getUserProtocol(user) !== protocol) {
		dispatch(
			onOpenNotification({
				message: `Please change your wallet to ${getProtocolTitle(protocol)} protocol and try again`,
				notificationType: 'info',
			})
		)

		return
	}

	if (protocol === ProtocolEnumType.NEAR) {
		modal.show()
	} else if (ERC20Protocols.includes(protocol as ProtocolEnumType)) {
		if (isBscBinanceWalletSelected(protocol as ProtocolEnumType)) {
			if (window.BinanceChain) {
				bscWallet.connect('bsc')
			} else {
				dispatch(
					onOpenNotification({
						message: getWalletExtensionNotAvailableMessage(WalletEnumType.BINANCE_WALLET),
						notificationType: 'info',
					})
				)
			}
		} else if (status !== 'unavailable') {
			await connect()
		} else {
			dispatch(
				onOpenNotification({
					message: getWalletExtensionNotAvailableMessage(WalletEnumType.METAMASK_WALLET),
					notificationType: 'info',
				})
			)
		}
	} else if (protocol === ProtocolEnumType.TRON) {
		await connectTron(user, dispatch)
	}
}

export const updateBalance = (
	account: string,
	ethereum: any,
	protocol: ProtocolEnumType,
	dispatch: Dispatch<{ payload: any }>
) => {
	if (protocol === ProtocolEnumType.NEAR) {
		updateNearTokensBalance(account, dispatch)
	} else if (ERC20Protocols.includes(protocol)) {
		updateMetaMaskTokensBalance(
			ethereum,
			account,
			protocol,
			dispatch
		)
	} else if (protocol === ProtocolEnumType.TRON) {
		updateTronTokensBalance(dispatch)
	}
}


export const isNativeWalletAccountBalanceEmpty = (
	protocol: ProtocolEnumType,
	walletAccount: string,
	walletBalances: WalletAccountBalances | null | undefined,
	ethereum: any,
	dispatch: Dispatch<{ payload: any }>
) => {
	const nativeAccountToken = getNativeAccountTokenByProtocol(protocol)
	if (!walletBalances
		|| !walletBalances[nativeAccountToken]
		|| !parseFloat(walletBalances[nativeAccountToken] ?? '0')
	) {
		dispatch(
			onOpenNotification({
				message: `Please make sure to have positive ${tokenSymbols[nativeAccountToken]} balance to pay for transaction gas fee`,
				notificationType: 'info',
			})
		)

		updateBalance(
			walletAccount as string,
			ethereum,
			protocol,
			dispatch
		)

		return true
	}

	return false
}

export function getIconByToken(token: WalletAccountBalanceToken) {
	switch (token) {
		case WalletAccountBalanceToken.NEAR:
		case WalletAccountBalanceToken.WRAP_NEAR:
		case WalletAccountBalanceToken.WRAP_NEAR_TESTNET:
			return nearWalletIcon
		case WalletAccountBalanceToken.ETH:
		case WalletAccountBalanceToken.WRAP_ETH:
		case WalletAccountBalanceToken.WRAP_ETH_SEPOLIA:
			return ethWalletIcon
		case WalletAccountBalanceToken.ETH_BUSD:
		case WalletAccountBalanceToken.ETH_BUSD_SEPOLIA:
			return busdIcon
		case WalletAccountBalanceToken.NEAR_USDC:
		case WalletAccountBalanceToken.NEAR_USDC_TESTNET:
		case WalletAccountBalanceToken.BSC_USDC:
		case WalletAccountBalanceToken.BSC_USDC_TESTNET:
		case WalletAccountBalanceToken.ETH_USDC:
		case WalletAccountBalanceToken.ETH_USDC_SEPOLIA:
		case WalletAccountBalanceToken.POLYGON_USDC:
		case WalletAccountBalanceToken.POLYGON_USDC_TESTNET:
			return usdcIcon
		case WalletAccountBalanceToken.BSC_BUSD:
		case WalletAccountBalanceToken.BSC_BUSD_TESTNET:
		case WalletAccountBalanceToken.POLYGON_BUSD:
		case WalletAccountBalanceToken.POLYGON_BUSD_TESTNET:
			return busdIcon
		case WalletAccountBalanceToken.ETH_GUSD:
		case WalletAccountBalanceToken.ETH_GUSD_SEPOLIA:
			return gusdIcon
		case WalletAccountBalanceToken.TRON:
		case WalletAccountBalanceToken.WRAP_TRON:
		case WalletAccountBalanceToken.WRAP_TRON_NILE:
			return tronWalletIcon
		case WalletAccountBalanceToken.BSC_BNB:
			return bscWalletIcon
		case WalletAccountBalanceToken.POLYGON_MATIC:
			return polygonWalletIcon
		default:
			return usdtIcon
	}
}

export const getTruncatedHash = (str: string) => str.length > 8 ? `${str.slice(0, 8)}...${str.slice(-4)}` : str
export const getTruncatedHashSixDigitsEnding = (str: string) => str.length > 8 ? `${str.slice(0, 8)}...${str.slice(-6)}` : str
