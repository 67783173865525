import React from 'react'
import {
	ITeam,
	IUser,
	RoleEnumType,
	SubscriptionEnumType,
	TeamRoleEnumType,
} from 'redux/user/types'

export enum SubscriptionItemEnumType {
	MEMBERS = 'members',
	APPROVALS = 'approvals',
	BATCH_PAYMENT = 'batch',
	SMART_CONTRACT = 'contract',
}

export const PENDING_INVOICE_COMPANY_MSG = 'Waiting for contractor to connect their wallet'
export const PENDING_INVOICE_CONTRACTOR_MSG = 'Please connect wallet to receive payment'

export const getCurrentSubscriptionItems = (subscription: SubscriptionEnumType) => {
	switch (subscription) {
		case SubscriptionEnumType.SOLO:
			return [
				SubscriptionItemEnumType.BATCH_PAYMENT,
			];
		case SubscriptionEnumType.TEAM:
			return [
				SubscriptionItemEnumType.BATCH_PAYMENT,
				SubscriptionItemEnumType.APPROVALS,
				SubscriptionItemEnumType.MEMBERS,
			];
		case SubscriptionEnumType.ESCROW:
			return [
				SubscriptionItemEnumType.BATCH_PAYMENT,
				SubscriptionItemEnumType.SMART_CONTRACT,
				SubscriptionItemEnumType.APPROVALS,
				SubscriptionItemEnumType.MEMBERS,
			];
		default:
			return [];
	}
};

export const hasCurrentSubscriptionItem = (
	user: IUser,
	subscriptionItem: SubscriptionItemEnumType
) => {
	if (user.role === RoleEnumType.COMPANY && user.team?.subscription) {
		const subscriptionItems = getCurrentSubscriptionItems(user.team?.subscription)

		return subscriptionItems.includes(subscriptionItem)
	}

	return false
}

const getName = (user: IUser) => user.name ? (
	user.role === RoleEnumType.CONTRACTOR
		? `${user.name} ${user.surname}`
		: user.name
) : ''

export const truncateString = (str: string) => str?.replace(/^(.{15}).{2,}/, '$1…') ?? ''

export const getTeamTitle = (team: ITeam | null, useEllipsis = true) => {
	if (!team?.name) {
		return 'Information required'
	}

	return useEllipsis ? truncateString(team.name) : team.name
}

export const getUserName = (user: IUser, useEllipsis = true) =>
	useEllipsis ? truncateString(getName(user)) : getName(user)

export const getFullUserTitle = (
	user: IUser,
	useNewLine = false,
	useEllipsis = false
) => {
	const name = getUserName(user, useEllipsis)

	if (user.team?.name) {
		return `${name}${useNewLine ? '\n' : ' from '}${user.team.name}`
	}

	return name
}

export const hasTeamUserRoles = (userTeamRoles: TeamRoleEnumType[], roles: TeamRoleEnumType[]) => userTeamRoles?.some(role => roles.includes(role))

export const getFormattedAccount = (accountId: string) =>
	accountId.length >= 22
		? `${accountId.slice(0, 18)}…${accountId.slice(-4)}`
		: accountId

export const getFirstChar = (str: string | null | undefined, fallbackChar = 'A') =>
	str && str?.length && str?.trim() !== '' ? str[0] : fallbackChar

export const stopPropagation = (event: React.MouseEvent<HTMLElement>) => {
	event.stopPropagation()
}

// eslint-disable-next-line no-promise-executor-return
export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const sendGtagEvent = (eventName: string, eventParams: { [key: string]: string } = {}) => {
	window?.gtag('event', eventName, eventParams);
}

export const TRON_REFRESH_TIMEOUT = 2500

export const ITEMS_PER_PAGE = 25
