import { WalletSelector } from '@near-wallet-selector/core'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'
import { IWalletInfo, walletList } from 'utils/wallets'
import { parse } from 'uuid'

import app_config from 'config/app_config'

import getConfig from 'config/near_config'
import { connect, providers, WalletConnection } from 'near-api-js'
import { INearTransfer, SignAndSendTransactionResponse, Wallet } from './nearWallet'

const nearConfig = getConfig(app_config.env.REACT_APP_NEAR_NETWORK as string)

const GAS_LIMIT = '300000000000000'
const DEPOSIT = '1'

export const getFTBalance = async (contractId: string, accountId: string) => {
	const nearConnection = await connect(nearConfig)

	const walletConnection = new WalletConnection(nearConnection, 'geek-pay')

	return Number(
		await walletConnection.account().viewFunction({
			contractId,
			methodName: 'ft_balance_of',
			args: {
				account_id: accountId,
			},
		})
	)
}

export const getBalance = async (accountId: string) => {
	const nearConnection = await connect(nearConfig)

	const account = await nearConnection.account(accountId)

	return Number((await account.getAccountBalance()).total)
}

export const nearTransferCall = async (
	contractId: string,
	deposit: string,
	msg: string
) => {
	const nearWallet = new Wallet({
		createAccessKeyFor: 'geek-pay',
	})

	await nearWallet.startUp()

	return nearWallet.callMethod({
		contractId,
		method: 'near_deposit',
		deposit,
		gas: GAS_LIMIT,
		args: [
			{
				msg,
			},
		],
	})
}

export const nearTransferCallDirect = async (params: INearTransfer[]) => {
	const nearWallet = new Wallet({
		createAccessKeyFor: 'geek-pay',
	})

	await nearWallet.startUp()

	return await nearWallet.bulkTransfer(params)
}

export const ftTransferCall = async (
	contractId: string,
	receiverId: string,
	amount: string,
	msg: string
) => {
	const nearWallet = new Wallet({
		createAccessKeyFor: 'geek-pay',
	})

	await nearWallet.startUp()

	return await nearWallet.callMethod({
		contractId,
		method: 'ft_transfer_call',
		deposit: DEPOSIT,
		gas: GAS_LIMIT,
		args: [
			{
				receiver_id: receiverId,
				amount,
				msg,
			},
		],
	})
}

export const ftTransferCallDirect = async (
	contractId: string,
	isEscrow: boolean,
	args: object[]
) => {
	const nearWallet = new Wallet({
		createAccessKeyFor: 'geek-pay',
	})

	await nearWallet.startUp()

	return await nearWallet.callMethod({
		contractId,
		method: isEscrow
			? 'ft_transfer_call'
			: 'ft_transfer',
		deposit: DEPOSIT,
		gas: GAS_LIMIT,
		args,
	})
}

export const ftWithdrawCall = async (
	contractId: string,
	invoiceId: string,
	tokenId: WalletAccountBalanceToken
): Promise<
	void | providers.FinalExecutionOutcome | SignAndSendTransactionResponse
> => {
	const nearWallet = new Wallet({
		createAccessKeyFor: 'geek-pay',
	})

	await nearWallet.startUp()

	return nearWallet.callMethod({
		contractId,
		method: 'withdraw',
		deposit: DEPOSIT,
		gas: GAS_LIMIT,
		args: [
			{
				invoice_id: Array.from(parse(invoiceId)),
				token_id: tokenId,
			},
		],
	})
}

export const ftVoidCall = async (
	contractId: string,
	invoiceId: string,
	tokenId: WalletAccountBalanceToken
): Promise<
	void | providers.FinalExecutionOutcome | SignAndSendTransactionResponse
> => {
	const nearWallet = new Wallet({
		createAccessKeyFor: 'geek-pay',
	})

	await nearWallet.startUp()

	return nearWallet.callMethod({
		contractId,
		method: 'rollback',
		deposit: DEPOSIT,
		gas: GAS_LIMIT,
		args: [
			{
				invoice_id: Array.from(parse(invoiceId)),
				token_id: tokenId,
			},
		],
	})
}

export const getTransactionResult = async (hash: string) => {
	const nearWallet = new Wallet({
		createAccessKeyFor: 'geek-pay',
	})

	await nearWallet.startUp()

	return nearWallet.getTransactionResult(hash)
}

export const getWalletInfo = (selector: WalletSelector) => {
	const walletStoreState = selector.store.getState()

	return walletList.find(
		(walletItem) =>
			walletItem.name === (walletStoreState?.selectedWalletId || 'near-wallet')
	) as IWalletInfo
}
